import { ChangeEvent, FC, type JSX } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useFormikContext } from 'formik';
import { IBudgetModel } from '@/shared/models/budgets/budget.model';
import { useTranslation } from 'react-i18next';
import { EDITOR_LS_CONSTANTS } from '@/editor/shared/constants/editor-LS.constants';
import { Moment } from 'moment';
import moment from 'moment';
import { IProductBudgetModel } from '@/shared/models/products/product.model';

const AdSetPartialForm: FC<{ updating: boolean; budgets: IBudgetModel[]; products: IProductBudgetModel[] }> = (props: {
  updating: boolean;
  budgets: IBudgetModel[];
  products: IProductBudgetModel[];
}): JSX.Element => {
  const { updating, budgets, products } = props;
  const { setFieldValue, getFieldMeta, handleChange } = useFormikContext();
  const { t } = useTranslation();
  const handleCustomChange = (e: ChangeEvent<{ name?: string; value: unknown }>) => {
    localStorage.removeItem(EDITOR_LS_CONSTANTS.ACTIVE_SOCIAL_MEDIA_PLATFORMS);
    handleChange(e);
  };

  return (
    <div>
      <Box component="div" sx={{ mt: '15px' }}>
        <DesktopDatePicker
          minDate={moment().add(2, 'h')}
          label={t('startDate')}
          format="DD-MM-YYYY"
          disabled={updating}
          value={moment(getFieldMeta('startTime').value as Moment)}
          onChange={(e) => {
            setFieldValue('startTime', e);
          }}
          slotProps={{
            textField: {
              variant: 'outlined',
              color: 'secondary',
              fullWidth: true,
            },
          }}
        />
      </Box>
      <Box component="div" sx={{ mt: '15px' }}>
        <DesktopDatePicker
          minDate={moment().add(1, 'd')}
          label={t('endDate')}
          format="DD-MM-YYYY"
          disabled={moment(getFieldMeta('endTime').value as Moment).isBefore(moment(), 'day')}
          value={moment(getFieldMeta('endTime').value as Moment)}
          onChange={(e: Moment | null) => {
            setFieldValue('endTime', e);
          }}
          slotProps={{
            textField: {
              variant: 'outlined',
              color: 'secondary',
              fullWidth: true,
            },
          }}
          // renderInput={(params) => (
          //   <CommonTextField
          //     {...params}
          //     error={Boolean(getFieldMeta('endTime').touched && getFieldMeta('endTime').error)}
          //     helperText={Boolean(getFieldMeta('endTime').touched && getFieldMeta('endTime').error)}
          //   />
          // )}
        />
      </Box>
      {updating ? (
        ''
      ) : (
        <FormControl disabled={updating} fullWidth sx={{ marginTop: '15px' }}>
          <InputLabel id="budget_input_label_id">{t('budget')}</InputLabel>
          <Select
            color="secondary"
            name="budgetId"
            labelId="budget_input_label_id"
            id="budget_input_id"
            value={((getFieldMeta('budgetId').value as string) || (getFieldMeta('productId').value as string)) ?? ''}
            onChange={handleCustomChange as any}
            input={<OutlinedInput label="Budget"></OutlinedInput>}
            renderValue={(value: string) => (
              <Box component="div">
                {budgets?.find((el) => el.id === value)?.title || products?.find((el) => el.id === value)?.title}
              </Box>
            )}>
            {budgets?.map(({ title, id }) => (
              <MenuItem key={id} value={id}>
                {title}
              </MenuItem>
            ))}
            {products?.map(({ title, id }) => (
              <MenuItem key={id} value={id}>
                {title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </div>
  );
};
export default AdSetPartialForm;
