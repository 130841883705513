import { ICarouselCreativeModel } from '@/shared/models/creatives/carousel-creative.model';
import { ICreateCreativesBatchResultModel } from '@/shared/models/creatives/create-creatives-batch-result.model';
import { IDynamicCreativeModel } from '@/shared/models/creatives/dynamic-creative.model';
import { ISingleCreativeModel } from '@/shared/models/creatives/single-creative.model';
import { api } from '@/shared/services/api/api';
import { PlatformType } from '../../shared/constants/available-platforms';
import { IBasicCreativeFormValues } from '../../shared/components/forms/creatives/models/basic-creative-form-values.model';
import { ICarouselCreativeFormValuesModel } from '../../shared/components/forms/creatives/models/carousel-creative-form-values';
import { IDynamicCreativeFormValuesModel } from '../../shared/components/forms/creatives/models/dynamic-creative-form-values.model';
import { ISingleCreativeFormValuesModel } from '../../shared/components/forms/creatives/models/single-creative-form-values.model';
import { IVideoCreativeFormValuesModel } from '../../shared/components/forms/creatives/models/video-creative-form.values.model';

const baseUrl = 'creatives';
const fitBaseCreative = (creative: IBasicCreativeFormValues) => ({
  
  link: creative.link,
  headline: creative.headline,
  description: creative.description,
  ownershipType: creative.ownershipType || null,
  propertyEstateType: creative.propertyEstateType || null,
  priceQuote: creative.priceQuote || null,
  stateDebt: creative.stateDebt || null,
  viewDate: creative.viewDate,
  areaInUsage: creative.areaInUsage || null,
  caption: creative.caption,
  nativeAd: creative.nativeAd,
  employeeName: creative.employeeName,
  employeeAvatar: creative.employeeAvatar,
  employeePhone: creative.employeePhone,
  employeeEmail: creative.employeeEmail,
  doohBrokerText: creative.doohBrokerText,
});

export const customerCreativesApi = api.injectEndpoints({
  endpoints: (build) => ({
    createSingleCreative: build.mutation<
      any, // TODO: fix type
      { platform: PlatformType; creative: ISingleCreativeFormValuesModel }
    >({
      query: ({ platform, creative }) => ({
        url: `${baseUrl}/types/single`,
        method: 'POST',
        body: {
          platform,
          ...fitBaseCreative(creative),
          mediaId: creative.media?.id,
        },
      }),
      invalidatesTags: ['CustomerCreatives'],
    }),
    createSingleCreativesBatch: build.mutation<
      ICreateCreativesBatchResultModel<ISingleCreativeModel>,
      { platform: PlatformType; creative: ISingleCreativeFormValuesModel }[]
    >({
      query: (creatives) => ({
        url: `${baseUrl}/types/single/batch`,
        method: 'POST',
        body: {
          items: creatives.map((value) => ({
            platform: value.platform,
            ...fitBaseCreative(value.creative),
            mediaId: value.creative.media?.id,
          })),
        },
      }),
      invalidatesTags: ['CustomerCreatives'],
    }),
    updateSingleCreative: build.mutation<
      ISingleCreativeModel,
      {
        id: string;
        platform: PlatformType;
        creative: ISingleCreativeFormValuesModel;
      }
    >({
      query: ({ id, platform, creative }) => ({
        url: `${baseUrl}/${id}/types/single`,
        method: 'PUT',
        body: {
          platform,
          ...fitBaseCreative(creative),
          mediaId: creative.media?.id,
        },
      }),
      invalidatesTags: ['CustomersOrders', 'CustomerCreatives'],
    }),
    createDynamicCreativesBatch: build.mutation<
      ICreateCreativesBatchResultModel<IDynamicCreativeModel>,
      { platform: PlatformType; creative: IDynamicCreativeFormValuesModel }[]
    >({
      query: (creatives) => ({
        url: `${baseUrl}/types/dynamic/batch`,
        method: 'POST',
        body: {
          items: creatives.map((value) => ({
            platform: value.platform,
            ...fitBaseCreative(value.creative),
            creativeMediaIds: value.creative.creativeMedias.map((el) => el.id),
          })),
        },
      }),
      invalidatesTags: ['CustomerCreatives'],
    }),
    updateDynamicCreative: build.mutation<
      ISingleCreativeModel,
      {
        id: string;
        platform: PlatformType;
        creative: IDynamicCreativeFormValuesModel;
      }
    >({
      query: ({ id, platform, creative }) => ({
        url: `${baseUrl}/${id}/types/dynamic`,
        method: 'PUT',
        body: {
          platform,
          ...fitBaseCreative(creative),
          creativeMediaIds: creative.creativeMedias?.map((el) => el.id),
        },
      }),
      invalidatesTags: ['CustomersOrders', 'CustomerCreatives'],
    }),
    createCarouselCreativesBatch: build.mutation<
      ICreateCreativesBatchResultModel<ICarouselCreativeModel>,
      { platform: PlatformType; creative: ICarouselCreativeFormValuesModel }[]
    >({
      query: (creatives) => ({
        url: `${baseUrl}/types/carousel/batch`,
        method: 'POST',
        body: {
          items: creatives.map((value) => ({
            ...fitBaseCreative(value.creative),
            platform: value.platform,
            creativeItems: value.creative.creativeItems.map((item) => {
              return {
                ...item,
                creativeMediaId: item.creativeMedia?.id,
              };
            }),
          })),
        },
      }),
      invalidatesTags: ['CustomerCreatives'],
    }),
    updateCarouselCreative: build.mutation<
      ISingleCreativeModel,
      {
        id: string;
        platform: PlatformType;
        creative: ICarouselCreativeFormValuesModel;
      }
    >({
      query: ({ id, platform, creative }) => ({
        url: `${baseUrl}/${id}/types/carousel`,
        method: 'PUT',
        body: {
          platform,
          ...fitBaseCreative(creative),
          creativeItems: creative.creativeItems.map((item) => {
            return {
              ...item,
              creativeMediaId: item.creativeMedia?.id,
            };
          }),
        },
      }),
      invalidatesTags: ['CustomersOrders', 'CustomerCreatives'],
    }),
    createVideoCreativesBatch: build.mutation<
      ICreateCreativesBatchResultModel<ISingleCreativeModel>,
      { platform: PlatformType; creative: IVideoCreativeFormValuesModel }[]
    >({
      query: (creatives) => ({
        url: `${baseUrl}/types/video/batch`,
        method: 'POST',
        body: {
          items: creatives.map((value) => ({
            platform: value.platform,
            ...fitBaseCreative(value.creative),
            mediaId: value.creative.media?.id,
          })),
        },
      }),
      invalidatesTags: ['CustomerCreatives'],
    }),
    updateVideoCreative: build.mutation<
      ISingleCreativeModel,
      {
        id: string;
        platform: PlatformType;
        creative: IVideoCreativeFormValuesModel;
      }
    >({
      query: ({ id, platform, creative }) => ({
        url: `${baseUrl}/${id}/types/video`,
        method: 'PUT',
        body: {
          platform,
          ...fitBaseCreative(creative),
          mediaId: creative.media?.id,
        },
      }),
      invalidatesTags: ['CustomersOrders', 'CustomerCreatives'],
    }),
    createSingleCreativesBrokerBatch: build.mutation<
      ICreateCreativesBatchResultModel<ISingleCreativeModel>,
      { id: string | null; creatives: { id?: string | null; platform: PlatformType; creative: any; }[]; }
    >({
      query: ({ id, creatives }) => {
        const formData = new FormData();

        creatives.forEach((value, index) => {
          if (value.platform === 'bidtheatre' || value.platform === 'boards') {
          const id =  localStorage.getItem('propertyInternalId')
            if (value.creative.employeeAvatar instanceof File) {
              formData.append('employeeAvatar', value.creative.employeeAvatar, value.creative.employeeAvatar.name);
            } else {
              const avatar = JSON.parse(localStorage.getItem('creativeForms_boards')! || localStorage.getItem('creativeForms_bidtheatre')!) || ''
              formData.append('employeeUrl', avatar.employeeAvatar ?? '')
              console.error('Invalid employeeAvatar file');
            }
            formData.append('employeeName', value.creative.employeeName ?? '');
            formData.append('employeePhone', value.creative.employeePhone ?? '');
            formData.append('employeeEmail', value.creative.employeeEmail ?? '');
            formData.append('propertyId', id ?? '');
            formData.append('doohBrokerText', value.creative.doohBrokerText ?? '');
            formData.append('doohTemplateType', value.creative.doohTemplateType ?? '');
          }
        });

        return {
          url: `bidtheatre/${id}/broker`,
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: ['CustomerCreatives'],
    }),
  }),
});

export const {
  useCreateSingleCreativeMutation,
  useCreateSingleCreativesBatchMutation,
  useCreateSingleCreativesBrokerBatchMutation,
  useCreateDynamicCreativesBatchMutation,
  useCreateCarouselCreativesBatchMutation,
  useUpdateSingleCreativeMutation,
  useUpdateDynamicCreativeMutation,
  useUpdateCarouselCreativeMutation,
  useCreateVideoCreativesBatchMutation,
  useUpdateVideoCreativeMutation,

} = customerCreativesApi;
