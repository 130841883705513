import {
  useCreateCarouselCreativesBatchMutation,
  useCreateDynamicCreativesBatchMutation,
  useCreateSingleCreativeMutation,
  useCreateSingleCreativesBatchMutation,
  useCreateSingleCreativesBrokerBatchMutation,
  useCreateVideoCreativesBatchMutation,
} from '@/editor/services/http/creatives.api';
import { AvailablePlatformsEnum, PlatformType } from '@/editor/shared/constants/available-platforms';
import CreativeTypesEnum, { CreativeType } from '@/editor/shared/constants/creatives-types.enum';
import { EDITOR_LS_CONSTANTS } from '@/editor/shared/constants/editor-LS.constants';
import { IBasicCreativeFormValues } from '@/editor/shared/components/forms/creatives/models/basic-creative-form-values.model';
import { ICarouselCreativeFormValuesModel } from '@/editor/shared/components/forms/creatives/models/carousel-creative-form-values';
import { IDynamicCreativeFormValuesModel } from '@/editor/shared/components/forms/creatives/models/dynamic-creative-form-values.model';
import { ISingleCreativeFormValuesModel } from '@/editor/shared/components/forms/creatives/models/single-creative-form-values.model';
import { IVideoCreativeFormValuesModel } from '@/editor/shared/components/forms/creatives/models/video-creative-form.values.model';
import {
  ICreativeFormsState,
  ICreativeFormsStatePerPlatform,
  resetValidPlatforms,
  setcreativeSubmissionProgress,
} from '@/editor/store/reducers/creative-step.slice';
import Loader from '@/shared/components/Loader';
import { IBaseCreativeModel } from '@/shared/models/creatives/base-creative.model';
import { getValueFromLs, setKeyValuePairToLS } from '@/shared/services/localstorage';
import { useAppDispatch, useAppSelector } from '@/store/hooks/redux';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { FC, useEffect, type JSX } from 'react';
import { toast } from 'react-hot-toast';
import validateCreatives from '../../../../services/creatives/creatives-validator.service';
import { IBaseStepperComponentPropertiesModel } from '../component-properties.model';
import usePrepareCreatives from './hooks/usePrepareCreatives';
import { useTranslation } from 'react-i18next';
import CommonButton from '@/shared/components/CommonButton';
import ArrowBackTwoTone from '@mui/icons-material/ArrowBackTwoTone';
import ArrowForward from '@mui/icons-material/ArrowForward';
import CreativesTypeResolver from '@/editor/shared/components/forms/creatives/CreativesTypesResolver';
import ExternalCompanyIdEnum from '@/shared/constants/external-company-id.enum';
import { useGetMeQuery } from '@/editor/services/http/users.api';

// TODO REFACTOR !!!

const CreativesStep: FC<IBaseStepperComponentPropertiesModel> = ({
  handleChangeActiveStep,
  handleBackBtnClicked,
}): JSX.Element => {
  const selectedCreativeType = useAppSelector((state) => state.creativeStepSliceReducer.selectedCreativeType);
  const creativeFormsState = useAppSelector((state) => state.creativeStepSliceReducer.creativeFormsState);
  const isCreativeFormInitialized = useAppSelector((state) => state.creativeStepSliceReducer.isInitialized);

  const { loading } = usePrepareCreatives();

  const platformsFromStoreIds = useAppSelector((state) => state.editorActivePlatformsReducer.activePlatforms);
  const renderedFormSubmissionState = useAppSelector(
    (state) => state.creativeStepSliceReducer.creativeSubmissionProgress
  );

  const activePlatforms = JSON.parse(getValueFromLs(EDITOR_LS_CONSTANTS.ACTIVE_SOCIAL_MEDIA_PLATFORMS) as string);
  const platformList: PlatformType[] = platformsFromStoreIds.length === 0 ? activePlatforms : platformsFromStoreIds;

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const { data: userData, isLoading: userDataLoading } = useGetMeQuery();
  // api post requests for creation
  const [createSingleCreativesBatch, { isLoading: singleCreativeCreationLoading }] =
    useCreateSingleCreativesBatchMutation();
  const [createDynamicCreativesBatch, { isLoading: dynamicCreativeCreationLoading }] =
    useCreateDynamicCreativesBatchMutation();
  const [createCarouselCreativesBatch, { isLoading: carouselCreativeCreationLoading }] =
    useCreateCarouselCreativesBatchMutation();
  const [createVideoCreativeBatch, { isLoading: videoCreativeCreationLoading }] =
    useCreateVideoCreativesBatchMutation();
  const [createSingleCreatives, { isLoading: singleCreativeLoading }] = useCreateSingleCreativeMutation();
  const [brokerMutation] = useCreateSingleCreativesBrokerBatchMutation();

  const handleNextStepClick = async () => {
    dispatch(setcreativeSubmissionProgress({ active: true, update: false, platform: null }));
  };

  const handleBackStepClicked = () => {
    dispatch(setcreativeSubmissionProgress({ active: false, update: false, platform: null }));
    dispatch(resetValidPlatforms());

    if (handleBackBtnClicked) {
      handleBackBtnClicked();
    }
  };

  useEffect(() => {
    if (!renderedFormSubmissionState.active) {
      return;
    }

    savingAction();
  }, [renderedFormSubmissionState.active]);

  const savingAction = async () => {
    if (
      !validateCreatives(
        creativeFormsState,
        platformList.filter((el) => el !== AvailablePlatformsEnum.DELTA),
        selectedCreativeType
      )
    ) {
      return;
    }

    switch (selectedCreativeType) {
      case CreativeTypesEnum.SINGLE:
        await createCreativesWithSingleElement<ISingleCreativeFormValuesModel>(
          CreativeTypesEnum.SINGLE,
          createSingleCreativesBatch
        );
        break;
      case CreativeTypesEnum.VIDEO:
        await createCreativesWithSingleElement<IVideoCreativeFormValuesModel>(
          CreativeTypesEnum.VIDEO,
          createVideoCreativeBatch
        );
        break;
      case CreativeTypesEnum.DYNAMIC:
        await createCreativesWithMultipleElements<IDynamicCreativeFormValuesModel>(
          CreativeTypesEnum.DYNAMIC,
          createDynamicCreativesBatch
        );
        break;
      case CreativeTypesEnum.CAROUSEL:
        await createCreativesWithMultipleElements<ICarouselCreativeFormValuesModel>(
          CreativeTypesEnum.CAROUSEL,
          createCarouselCreativesBatch
        );
        break;
    }

    handleChangeActiveStep(3);
  };

  async function createCreativeWithMultipleItems<T extends IBasicCreativeFormValues>(
    requests: any[],
    platformList: PlatformType[],
    creativeType: string,
    creativeFormState: ICreativeFormsState,
    handler: any
  ): Promise<void> {
    const map = platformList
      .filter(
        (el) =>
          el !== AvailablePlatformsEnum.DELTA &&
          el !== AvailablePlatformsEnum.BIDTHEATRE &&
          el !== AvailablePlatformsEnum.BOARDS
      )
      .map((platform) => ({
        platform: platform,
        creative: creativeFormState[platform][creativeType as keyof ICreativeFormsStatePerPlatform] as unknown as T,
      }));

    let bidtheatreResponse: IBaseCreativeModel;
    let boardsResponse: IBaseCreativeModel;

    if (platformList.includes(AvailablePlatformsEnum.BIDTHEATRE)) {
      bidtheatreResponse = await createSingleImageCreative(
        creativeFormState[AvailablePlatformsEnum.BIDTHEATRE][
          CreativeTypesEnum.SINGLE
        ] as ISingleCreativeFormValuesModel,
        AvailablePlatformsEnum.BIDTHEATRE
      );
    }

    if (platformList.includes(AvailablePlatformsEnum.BOARDS)) {
      boardsResponse = await createSingleImageCreative(
        creativeFormState[AvailablePlatformsEnum.BOARDS][CreativeTypesEnum.SINGLE] as ISingleCreativeFormValuesModel,
        AvailablePlatformsEnum.BOARDS
      );
    }

    requests.push(handler(map));

    type ResponseKey = 'items';
    type Responses = Record<ResponseKey, IBaseCreativeModel[]>;

    return new Promise(async (resolve, reject) => {
      try {
        const res: Responses[] = await Promise.all(requests.map((el) => el.unwrap() as Promise<Responses>));

        const finalResult = res
          .map((el) =>
            el.items.map((item: IBaseCreativeModel) => ({
              id: item.id,
              platform: item.platform,
            }))
          )
          .flat();

        if (platformList.includes(AvailablePlatformsEnum.BIDTHEATRE)) {
          finalResult.push({ id: bidtheatreResponse?.id, platform: bidtheatreResponse.platform });
        }
        if (platformList.includes(AvailablePlatformsEnum.BOARDS)) {
          finalResult.push({ id: boardsResponse?.id, platform: boardsResponse.platform });
        }

        setKeyValuePairToLS(EDITOR_LS_CONSTANTS.CREATED_CREATIVES, JSON.stringify(finalResult));

        resolve();
      } catch (err) {
        reject(err);
      }
    });
  }

  const createSingleImageCreative = async (values: ISingleCreativeFormValuesModel, platform: PlatformType) => {
    try {
      const res = (await toast.promise(
        createSingleCreatives({
          platform: platform,
          creative: values,
        }).unwrap(),
        {
          loading: t('creatingCreatives'),
          success: t('successfullyCreated'),
          error: t('somethingWrong'),
        }
      )) as IBaseCreativeModel;

      if (
        platform === AvailablePlatformsEnum.BIDTHEATRE &&
        (userData?.companyExternalId === ExternalCompanyIdEnum.SMG ||
          userData?.companyExternalId === ExternalCompanyIdEnum.FREMEIENDOMSMEGLING ||
          userData?.companyExternalId === ExternalCompanyIdEnum.MSPECS ||
          userData?.companyExternalId === ExternalCompanyIdEnum.STRAND_MAKLERI ||
          userData?.companyExternalId === ExternalCompanyIdEnum.STUDIO_FASTIGHETSMAKLERI ||
          userData?.companyExternalId === ExternalCompanyIdEnum.HOME_BY_DEAN)
      ) {
        const bidtheatreId = res.id;
        localStorage.setItem('bidid', bidtheatreId);
        const brokerData = [
          {
            id: bidtheatreId,
            platform: AvailablePlatformsEnum.BIDTHEATRE,
            creative: values,
          },
        ];
        await toast.promise(
          brokerMutation({
            id: bidtheatreId,
            creatives: brokerData,
          }).unwrap(),
          {
            loading: t('creatingCreatives'),
            success: t('successfullyCreated'),
            error: t('somethingWrong'),
          }
        );
      }
      if (platform === AvailablePlatformsEnum.BOARDS) {
        const boardsId = res.id;
        localStorage.setItem('boardsdid', boardsId);
        const brokerData = [
          {
            id: boardsId,
            platform: AvailablePlatformsEnum.BOARDS,
            creative: values,
          },
        ];
        await toast.promise(
          brokerMutation({
            id: boardsId,
            creatives: brokerData,
          }).unwrap(),
          {
            loading: t('creatingCreatives'),
            success: t('successfullyCreated'),
            error: t('somethingWrong'),
          }
        );
      }
      return res;
    } catch (error) {
      console.error('Error creating single image creative:', error);
      throw error;
    }
  };

  async function createCreativesWithSingleElement<
    TCreative extends ISingleCreativeFormValuesModel | IVideoCreativeFormValuesModel,
  >(creativeType: CreativeType, handler: any) {
    const map = platformList.map((platform: PlatformType) => ({
      platform: platform,
      creative: creativeFormsState[platform][creativeType]! as TCreative,
    }));

    const res = (await toast.promise(handler(map).unwrap(), {
      loading: t('creatingCreatives'),
      success: t('successfullyCreated'),
      error: t('somethingWrong'),
    })) as Record<'items', IBaseCreativeModel[]>;

    const bidtheatreItem = res.items.find(
      (item) =>
        item.platform === 'bidtheatre' &&
        (userData?.companyExternalId === ExternalCompanyIdEnum.SMG ||
          userData?.companyExternalId === ExternalCompanyIdEnum.FREMEIENDOMSMEGLING ||
          userData?.companyExternalId === ExternalCompanyIdEnum.MSPECS ||
          userData?.companyExternalId === ExternalCompanyIdEnum.STRAND_MAKLERI ||
          userData?.companyExternalId === ExternalCompanyIdEnum.STUDIO_FASTIGHETSMAKLERI ||
          userData?.companyExternalId === ExternalCompanyIdEnum.HOME_BY_DEAN)
    );

    if (bidtheatreItem) {
      const bidtheatreId = bidtheatreItem.id;
      localStorage.setItem('bidid', bidtheatreId);
      await toast.promise(
        brokerMutation({
          id: bidtheatreId,
          creatives: map.filter((item) => item.platform === 'bidtheatre'),
        }).unwrap(),
        {
          loading: t('creatingCreatives'),
          success: t('successfullyCreated'),
          error: t('somethingWrong'),
        }
      );
    }

    const boardsItem = res.items.find((item) => item.platform === 'boards');

    if (boardsItem) {
      const boardsId = boardsItem.id;
      localStorage.setItem('boardsid', boardsId);

      await toast.promise(
        brokerMutation({
          id: boardsId,
          creatives: map.filter((item) => item.platform === 'boards'),
        }).unwrap(),
        {
          loading: t('creatingCreatives'),
          success: t('successfullyCreated'),
          error: t('somethingWrong'),
        }
      );
    }

    setKeyValuePairToLS(
      EDITOR_LS_CONSTANTS.CREATED_CREATIVES,
      JSON.stringify(res.items.map((el) => ({ id: el.id, platform: el.platform })))
    );
  }

  async function createCreativesWithMultipleElements<
    TCreative extends ICarouselCreativeFormValuesModel | IDynamicCreativeFormValuesModel,
  >(creativeType: CreativeType, handler: any) {
    await toast.promise(
      createCreativeWithMultipleItems<TCreative>([], platformList, creativeType, creativeFormsState, handler),
      {
        loading: t('creatingCreatives'),
        success: t('successfullyCreated'),
        error: t('somethingWrong'),
      }
    );
  }

  return (
    <>
      {loading || !isCreativeFormInitialized ? (
        <Loader />
      ) : (
        <Grid container>
          <Grid item xs={12} sx={{ p: 1 }}>
            <CreativesTypeResolver availablePlatforms={platformList} creativeType={selectedCreativeType} />
          </Grid>

          <Grid item xs={12} sx={{ p: 1 }}>
            <Card sx={{ backgroundColor: '#FFF', display: 'flex', justifyContent: 'space-between', p: 1.5 }}>
              <CommonButton
                size="small"
                onClick={handleBackStepClicked}
                disabled={
                  singleCreativeCreationLoading ||
                  dynamicCreativeCreationLoading ||
                  carouselCreativeCreationLoading ||
                  videoCreativeCreationLoading
                }
                variant={'outlined'}
                startIcon={<ArrowBackTwoTone fontSize="small" />}>
                {t('back')}
              </CommonButton>
              <CommonButton
                size="small"
                onClick={handleNextStepClick}
                disabled={
                  singleCreativeCreationLoading ||
                  dynamicCreativeCreationLoading ||
                  carouselCreativeCreationLoading ||
                  videoCreativeCreationLoading
                }
                endIcon={<ArrowForward fontSize="small" />}>
                {t('submit')}
              </CommonButton>
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default CreativesStep;
