import { IAgentDetailsProps } from '@/shared/models/orders/single-order-with-details.model';
import { FC, type JSX } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

interface ISormeglerenFooterProps {
  userDetails: IAgentDetailsProps;
}

const SormeglerenFooter: FC<ISormeglerenFooterProps> = (props: ISormeglerenFooterProps): JSX.Element => {
  const { userDetails } = props;

  return (
    <Grid
      container
      item
      sx={{
        backgroundColor: '#1e0e3c',
        height: '10rem',
        minHeight: '10rem',
        display: 'flex',
        justifyContent: 'flex-end',
      }}>
      <Grid item xs={4} md={2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
        <Box
          component="div"
          sx={{
            height: '100px',
            width: '100px',
            backgroundSize: 'cover',
            backgroundImage: `url(${userDetails.agentImage})`,
            borderRadius: '50%',
          }}
        />
      </Grid>
      <Grid item xs={8} md={3} display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
        <Box color="#fff" ml={2}>
          <Typography variant={'h5'}>{userDetails.agentName + ' ' + userDetails.agentLastName}</Typography>
          <Typography paragraph>{userDetails.agentTitle}</Typography>
          <Typography variant={'h5'} fontWeight={100}>
            {userDetails.agentEmail}
          </Typography>
          <Typography variant={'h5'}>{userDetails.agentPhone}</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SormeglerenFooter;
