import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC, type JSX } from 'react';
import { IBasicCreativeFormValues } from '@/editor/shared/components/forms/creatives/models/basic-creative-form-values.model';
import { useTranslation } from 'react-i18next';
export interface IBoardsSummaryProps {
  currentCreativeForm: IBasicCreativeFormValues;
}
const BoardsSummary: FC<IBoardsSummaryProps> = ({ currentCreativeForm }): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ fontWeight: 700 }}>
          {t('caption')}:
        </Typography>
        <Typography variant="h6">{currentCreativeForm?.caption}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ fontWeight: 700 }}>
          {t('link')}:
        </Typography>
        <Typography variant="h6">{currentCreativeForm?.link}</Typography>
      </Grid>
      {currentCreativeForm?.doohTemplateType === 'AfterMarketing_Template' && (
        <>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ fontWeight: 700 }}>
              {t('Broker Name')}:
            </Typography>
            <Typography variant="h6">{currentCreativeForm?.employeeName}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ fontWeight: 700 }}>
              {t('Broker Text')}:
            </Typography>
            <Typography variant="h6">{currentCreativeForm?.doohBrokerText}</Typography>
          </Grid>
        </>
      )}
      {currentCreativeForm?.doohTemplateType === 'Regular_Template' && (
        <>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ fontWeight: 700 }}>
              {t('areaInUsage')}:
            </Typography>
            <Typography variant="h6">{currentCreativeForm?.areaInUsage}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ fontWeight: 700 }}>
              {t('viewDate')}:
            </Typography>
            <Typography variant="h6">{currentCreativeForm?.viewDate}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ fontWeight: 700 }}>
              {t('propertyDebt')}:
            </Typography>
            <Typography variant="h6">{currentCreativeForm?.stateDebt}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ fontWeight: 700 }}>
              {t('description')}:
            </Typography>
            <Typography variant="h6">{currentCreativeForm?.description}</Typography>
          </Grid>
        </>
      )}
    </div>
  );
};
export default BoardsSummary;
