import { FC, type JSX } from 'react';
import { IBasicCreativeFormValues } from '@/editor/shared/components/forms/creatives/models/basic-creative-form-values.model';
import Box from '@mui/material/Box';
import BoardsPreviewResolver from './BoardsPreviewResolver';

const BoardsPreviewSummary: FC<{
  values: IBasicCreativeFormValues;
  externalCompanyId: string;
}> = ({ values, externalCompanyId }): JSX.Element => {
  return (
    <Box maxWidth="600px">
      <BoardsPreviewResolver
        caption={values.caption!}
        companyExternalId={externalCompanyId}
        employeeEmail={values.employeeEmail}
        employeeAvatar={values.employeeAvatar}
        employeeName={values.employeeName}
        employeePhone={values.employeePhone}
        link={values.link!}
        doohBrokerText={values.doohBrokerText!}
        doohTemplateType={values.doohTemplateType}
        areaInUsage={values.areaInUsage}
        viewDate={values.viewDate}
        propertyType={values.propertyEstateType}
        propertyDebt={values.stateDebt}
        propertyPriceQuote={values.priceQuote}
        description={values.description}
        ownershipType={values.ownershipType}
      />
    </Box>
  );
};
export default BoardsPreviewSummary;
