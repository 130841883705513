import DownloadIcon from '@mui/icons-material/Download';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import { FC, type JSX } from 'react';
import * as yup from 'yup';
import CommonTextField from '../../../../../shared/components/CommonTextField';
import { IEditorOrderFormPropertiesBaseModel } from '@/editor/shared/components/forms/editor-order-form-properties-base.model';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import UploadFileRounded from '@mui/icons-material/UploadFileRounded';
import { IBaseStepperComponentPropertiesModel } from '../component-properties.model';
import { usePropertyRegistration } from './hooks/usePropertyRegistration';
import { FormikProvider } from 'formik';
export interface IRegisterCreateEditFormValues {
  address: string;
  propertyId: string;
}
export interface IRegisterEditPropertyFormProps
  extends IEditorOrderFormPropertiesBaseModel<IRegisterCreateEditFormValues> {
  loading: boolean;
  handleSubmit: (values: IRegisterCreateEditFormValues) => Promise<void>;
}

const RegisterEditPropertyForm: FC<IBaseStepperComponentPropertiesModel> = ({
  handleChangeActiveStep,
  handleBackBtnClicked,
}): JSX.Element => {
  const { t } = useTranslation();
  const { formik, isRegistering, isLoadingRetailData, fetchRetailData } =
    usePropertyRegistration(handleChangeActiveStep);

  const { values, handleChange, touched, errors, getFieldMeta } = formik;

  return (
    <FormikProvider value={formik}>
      <form
        noValidate
        onKeyDown={(e) => (e.key === 'Enter' ? e.preventDefault() : null)}
        onSubmit={formik.handleSubmit}>
        <CommonTextField
          name="propertyId"
          label={t('generatedPropertyId')}
          required
          value={values.propertyId}
          onChange={handleChange}
          error={Boolean(touched.propertyId && errors.propertyId)}
          helperText={touched.propertyId && errors.propertyId}
          disabled={isLoadingRetailData}
          InputProps={{
            endAdornment: (
              <InputAdornment
                onClick={() => fetchRetailData(getFieldMeta('propertyId').value as string)}
                position="end"
                sx={{ cursor: 'pointer', m: 0 }}>
                {isLoadingRetailData ? (
                  <Box sx={{ position: 'relative', pr: 0.5 }}>
                    <CircularProgress
                      variant="indeterminate"
                      size={32}
                      sx={{
                        color: '#000',
                        position: 'absolute',
                        top: -7,
                        left: -8,
                        zIndex: 1,
                      }}
                    />
                    <UploadFileRounded sx={{ fontSize: 16 }} color="secondary" />
                  </Box>
                ) : (
                  <Tooltip title={t('downloadIdTooltip')} placement="right">
                    <DownloadIcon color="secondary" />
                  </Tooltip>
                )}
              </InputAdornment>
            ),
          }}
        />
        <CommonTextField
          sx={{ mt: 2 }}
          name="address"
          label={t('address')}
          required
          value={values.address}
          onChange={handleChange}
          error={Boolean(touched.address && errors.address)}
          helperText={touched.address && errors.address}
        />
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          sx={{ mt: '16px', float: 'right' }}
          disabled={isRegistering}>
          <CircularProgress
            variant="indeterminate"
            size={20}
            sx={{
              position: 'absolute',
              visibility: isRegistering ? 'visible' : 'hidden',
              color: '#FFF',
            }}
          />
          <Box
            sx={{
              visibility: isRegistering ? 'hidden' : 'visible',
            }}>
            {t('register')}
          </Box>
        </Button>
      </form>
    </FormikProvider>
  );
};
export default RegisterEditPropertyForm;
