import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { IAdSetModel } from '../../../shared/models/adsets/adset.model';
import { api } from '../../../shared/services/api/api';
import { IAdSettingsFormValues } from '../../shared/components/forms/ad-settings/AdSettingsForm';
import moment from 'moment';

export type AdSetFormValuesType = Pick<IAdSettingsFormValues, 'title' | 'startTime' | 'endTime' | 'places'> & {
  spreadingBudgetInPercentage: number;
};

const baseUrl = 'adsets';

export const customerAdSetsApi = api.injectEndpoints({
  endpoints: (build) => ({
    createAdSetsBatch: build.mutation<IAdSetModel[], { platforms: string[]; values: AdSetFormValuesType[] }>({
      queryFn: async (_arg, _queryApi, _extraOptions, _fetchWithBQ) => {
        const tasks: any[] = [];
        _arg.values.forEach((el, key) => {
          tasks.push(
            _fetchWithBQ({
              url: `${baseUrl}`,
              method: 'POST',
              body: el,
            })
          );
        });

        const results = await Promise.all(tasks);
        const successes: IAdSetModel[] = results
          .filter((result) => result.data)
          .map((result) => result.data as IAdSetModel);
        const errors: FetchBaseQueryError[] = results
          .filter((result) => result.error != null)
          .map((result) => result.error as FetchBaseQueryError);

        if (errors.length > 0) return { error: errors[0] };

        return { data: successes };
      },
      invalidatesTags: ['CustomerAdSets'],
    }),
    updateAdSet: build.mutation<void, { id: string; value: Partial<AdSetFormValuesType> }>({
      query: ({ id, value }) => ({
        url: `${baseUrl}/${id}`,
        method: 'PUT',
        body: {
          title: value.title,
          endTime: moment(value.endTime).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
          locations: value.places?.map((place) => ({
            address: place.address,
            latitude: place.center.lat,
            longitude: place.center.lng,
            radius: place.radius,
          })),
        },
      }),
      invalidatesTags: ['CustomerAdSets', 'CustomersOrders'],
    }),
  }),
});

export const { useCreateAdSetsBatchMutation, useUpdateAdSetMutation } = customerAdSetsApi;
