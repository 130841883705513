import { useLazyGetMediaLibraryImagesQuery } from '@/editor/services/http/media-library.api';
import { AvailablePlatformsEnum, platformsToCropImage } from '@/editor/shared/constants/available-platforms';

import { IMediaLibraryImageModel } from '@/shared/models/media-library/media-library-image.model';
import usePlatformMedia from './usePlatformMedia';

export interface FilteredImages {
  platform: AvailablePlatformsEnum;
  images: IMediaLibraryImageModel[];
}

const useMediaLibrary = () => {
  const [
    loadMediaLibrary,
    { data: imagesLibrary, isLoading: mediaLibraryImagesLoading, isFetching: mediaLibraryFetching },
  ] = useLazyGetMediaLibraryImagesQuery();
  const { dispatchMediaPerPlatform } = usePlatformMedia();

  const initializeMediaLibrary = async (propertyId: string) => {
    const res = await loadMediaLibrary({ propertyId }, true);
    initializeImages(res.data!);
  };

  const getMediaLibraryData = async (propertyId: string) => {
    const res = await loadMediaLibrary({ propertyId }, false);
    return res.data;
  };

  const initializeImages = (data: IMediaLibraryImageModel[]) => {
    const filteredByRequirements: FilteredImages[] = platformsToCropImage.map((requirements) => ({
      platform: requirements.platform,
      images: data?.filter((image) => image.width === requirements.width && image.height === requirements.height) ?? [],
    }));

    // Try to find parent/source of cropped images, so selection will be all the same on each platform.
    // Ex. Snapchat -> 3 cropped, Meta - will have 3 original images of snapchat cropped images etc. So uncropped(source) -> cropped
    const firstImagesFromFiltered = filteredByRequirements.find((el) => el.images.length > 0)?.images;
    const sources: string[] = firstImagesFromFiltered?.filter((el) => el.sourceId).map((el) => el.sourceId!) ?? [];
    const imagesToChoose: IMediaLibraryImageModel[] = [];

    for (const source of sources) {
      imagesToChoose.push(data?.find((el) => el.id === source)!);
    }

    dispatchMediaPerPlatform(filteredByRequirements, imagesToChoose, data);
  };

  return { initializeMediaLibrary, getMediaLibraryData, mediaLibraryFetching, imagesLibrary };
};

export default useMediaLibrary;
