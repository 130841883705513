import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import { getValueFromLs, setKeyValuePairToLS } from '@/shared/services/localstorage';
import { useAppDispatch } from '@/store/hooks/redux';
import { EDITOR_LS_CONSTANTS } from '../../shared/constants/editor-LS.constants';
import { refreshCreativeFormState } from '../../store/reducers/creative-step.slice';
import StepResolver from './components/StepResolver';
import StepStack from './components/stepper/StepStack';

// TODO REFACTOR

export default function CreateAdsStepsPage() {
  const dispatch = useAppDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const currentStep = getValueFromLs(EDITOR_LS_CONSTANTS.CREATE_ORDER_ACTIVE_STEP);

  useEffect(() => {
    if (currentStep) {
      setActiveStep(parseInt(currentStep));
    }
  }, [currentStep]);

  useEffect(() => {
    return () => {
      dispatch(refreshCreativeFormState());
    };
  }, []);

  const handleActiveStepChanged = (step: number) => {
    setActiveStep(step);
    setKeyValuePairToLS(EDITOR_LS_CONSTANTS.CREATE_ORDER_ACTIVE_STEP, step.toString());
  };

  const handleBackBtnClicked = () => {
    if (activeStep !== 0) {
      setActiveStep((prevState) => prevState - 1);
      setKeyValuePairToLS(EDITOR_LS_CONSTANTS.CREATE_ORDER_ACTIVE_STEP, (activeStep - 1).toString());
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} sx={{ display: { xs: 'none', sm: 'flex' } }}>
        <Box width={'100vw'} p={'8px 0'} alignSelf={'flex-start'} sx={{ backgroundColor: '#fff' }}>
          <StepStack activeStep={activeStep} />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <StepResolver
          activeStep={activeStep}
          handleBackBtnClicked={handleBackBtnClicked}
          handleChangeActiveStep={handleActiveStepChanged}
        />
      </Grid>
    </Grid>
  );
}
