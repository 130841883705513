import { useLazyGetRetailPropertyDataQuery } from '@/editor/services/http/retail-data-provider.api';
import { initiateCreativeFormsState } from '@/editor/store/reducers/creative-step.slice';
import { useAppDispatch, useAppSelector } from '@/store/hooks/redux';
import { propertyDataService } from '../services';
import { IBasicCreativeFormValues } from '@/editor/shared/components/forms/creatives/models/basic-creative-form-values.model';
import { useState } from 'react';

const usePropertyData = () => {
  const [formData, setFormData] = useState<IBasicCreativeFormValues>();
  const propertyExtId = useAppSelector((state) => state.propertyInfoSliceReducer.propertyExtId);
  const companyId = useAppSelector((state) => state.propertyInfoSliceReducer.companyId);
  const [
    loadRetailPropertyData,
    {
      data: propertyData,
      isLoading: isLoadPropertyLoading,
      isSuccess: isLoadPropertySuccess,
      isError: isLoadPropertyError,
      isFetching: isLoadPropertyFetching,
    },
  ] = useLazyGetRetailPropertyDataQuery();

  const dispatch = useAppDispatch();

  const preparePropertyData = async () => {
    await loadRetailPropertyData({ propertyId: propertyExtId as string, companyId: companyId }, true).then((res) => {
      const initialValues = propertyDataService.transformToFormValues(res.data!);
      dispatch(initiateCreativeFormsState({ initialValues }));
      setFormData(initialValues);
      return initialValues;
    });
  };

  return {
    propertyData,
    formData,
    preparePropertyData,
    loadingState: {
      isLoading: isLoadPropertyLoading,
      isSuccess: isLoadPropertySuccess,
      isError: isLoadPropertyError,
      isFetching: isLoadPropertyFetching,
    },
  };
};

export default usePropertyData;
