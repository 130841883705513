import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { FC, type JSX } from 'react';
import RegisterEditPropertyForm from './RegisterEditPropertyForm';
import { IBaseStepperComponentPropertiesModel } from '../component-properties.model';

const RegisterPropertyStep: FC<IBaseStepperComponentPropertiesModel> = ({
  handleChangeActiveStep,
  handleBackBtnClicked,
}): JSX.Element => {
  return (
    <div>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
        <Paper sx={{ p: 4 }}>
          <RegisterEditPropertyForm
            handleChangeActiveStep={handleChangeActiveStep}
            handleBackBtnClicked={handleBackBtnClicked}
          />
        </Paper>
      </Grid>
    </div>
  );
};

export default RegisterPropertyStep;
