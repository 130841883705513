import { deleteKeyValuePairFromLS } from '@/shared/services/localstorage';
import { EDITOR_LS_CONSTANTS } from '@/editor/shared/constants/editor-LS.constants';
import { setCarouselPropertyExtId } from '@/editor/store/reducers/property-info.slice';
import { IPropertyApiResponseModel } from '@/shared/models/property.api.response.model';
import { ICarouselCreativeFormValuesModel, ICarouselCreativeItemFormValuesModel } from './models/carousel-creative-form-values';
import { FormikContextType } from 'formik';
import { PlatformType } from '@/editor/shared/constants/available-platforms';
import { IMediaLibraryImageModel } from '@/shared/models/media-library/media-library-image.model';

interface Toast {
  error: (message: string) => void;
}

export const handleError = (errorCondition: boolean, dispatch: Function, toast: Toast) => {
  if (errorCondition) {
    toast.error('Unknown property id');
    deleteKeyValuePairFromLS(EDITOR_LS_CONSTANTS.PROPERTY_CAROUSELL_ID);
    dispatch(setCarouselPropertyExtId(''));
  }
};

export const syncDownloadedData = (
  downloadedCarouselIndex: string | null, 
  currentData: IPropertyApiResponseModel, 
  existingDownloadedData: any,
  setKeyValuePairToLS: Function
) => {
  if (downloadedCarouselIndex && currentData !== null) {
    const index = parseInt(downloadedCarouselIndex, 10);
    const carouselData = generateCarouselData(currentData);

    if (JSON.stringify(existingDownloadedData[index]) !== JSON.stringify(carouselData)) {
      existingDownloadedData[index] = carouselData;
      setKeyValuePairToLS(EDITOR_LS_CONSTANTS.DOWNLOADED_CAROUSEL_DATA, JSON.stringify(existingDownloadedData));
    }
  }
};

export const generateCarouselData = (data: IPropertyApiResponseModel) => ({
  propertyId: data?.propertyId || '',
  headline: data?.propertyAddress || '',
  caption: data?.propertyDescription || '',
  link: data?.propertyLink || '',
  description: data?.propertyHeading || '',
});

export const updateFormikValues = (
  formikCtx: FormikContextType<ICarouselCreativeFormValuesModel>,
  existingDownloadedData: ICarouselCreativeItemFormValuesModel[],
  platform: PlatformType,
  updateCarouselItemField: Function,
  mediaLibraryImageData: IMediaLibraryImageModel[] | undefined,
  downloadedCarouselIndex: string
) => {
  existingDownloadedData.forEach((item, idx) => {
  const newValue = mediaLibraryImageData ? mediaLibraryImageData![0] : null;
    if (formikCtx.values.creativeItems[idx]) {
      formikCtx.setFieldValue(`creativeItems[${idx}].propertyId`, item.propertyId || '');
      formikCtx.setFieldValue(`creativeItems[${idx}].headline`, item.headline || '');
      formikCtx.setFieldValue(`creativeItems[${idx}].caption`, item.caption || '');
      formikCtx.setFieldValue(`creativeItems[${idx}].link`, item.link || '');
      formikCtx.setFieldValue(`creativeItems[${idx}].description`, item.description || '');
      formikCtx.setFieldValue(`creativeItems[${downloadedCarouselIndex}].creativeMedia`, newValue);
      updateCarouselItemField(platform, idx, 'propertyId', item.propertyId!);
      updateCarouselItemField(platform, idx, 'headline', item.headline!);
      updateCarouselItemField(platform, idx, 'caption', item.caption!);
      updateCarouselItemField(platform, idx, 'link', item.link!);
      updateCarouselItemField(platform, idx, 'description', item.description!);
      updateCarouselItemField(platform, downloadedCarouselIndex, 'creativeMedia', newValue!);
    }
  });
};