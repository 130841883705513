import { AvailablePlatformsEnum, PlatformType } from '@/editor/shared/constants/available-platforms';
import CreativeTypesEnum from '@/editor/shared/constants/creatives-types.enum';
import { EDITOR_LS_CONSTANTS } from '@/editor/shared/constants/editor-LS.constants';
import {
  setCreativeItemsOnCarousel,
  setMediaOnDynamic,
  setMediaOnSingle,
} from '@/editor/store/reducers/creative-step.slice';
import { getValueFromLs } from '@/shared/services/localstorage';
import { useAppDispatch, useAppSelector } from '@/store/hooks/redux';
import { FilteredImages } from './useMediaLibrary';
import { IMediaLibraryImageModel } from '@/shared/models/media-library/media-library-image.model';

const usePlatformMedia = () => {
  const selectedCreativeType = useAppSelector((state) => state.creativeStepSliceReducer.selectedCreativeType);
  const creativeFormsState = useAppSelector((state) => state.creativeStepSliceReducer.creativeFormsState);
  const activePlatforms = JSON.parse(getValueFromLs(EDITOR_LS_CONSTANTS.ACTIVE_SOCIAL_MEDIA_PLATFORMS) as string);
  const dispatch = useAppDispatch();

  const dispatchMediaPerPlatform = (
    filteredByRequirements: FilteredImages[],
    imagesToChoose: IMediaLibraryImageModel[],
    mediaLibraryImageData: IMediaLibraryImageModel[]
  ) => {
    for (const platformId of activePlatforms) {
      const typedPlatform = platformId.toLowerCase() as PlatformType;
      let appropriateImages = filteredByRequirements.find((el) => el.platform === typedPlatform)?.images;

      if (!appropriateImages?.length) {
        appropriateImages = imagesToChoose?.length > 0 ? imagesToChoose : mediaLibraryImageData;
      }

      if (!appropriateImages?.length) return;

      if (
        typedPlatform === AvailablePlatformsEnum.DELTA ||
        typedPlatform === AvailablePlatformsEnum.BIDTHEATRE ||
        typedPlatform === AvailablePlatformsEnum.BOARDS
      ) {
        dispatch(setMediaOnSingle({ media: appropriateImages![0], platform: typedPlatform }));
        continue;
      }

      switch (selectedCreativeType) {
        case CreativeTypesEnum.SINGLE:
          dispatch(setMediaOnSingle({ media: appropriateImages[0]!, platform: typedPlatform }));
          break;
        case CreativeTypesEnum.DYNAMIC:
          dispatch(
            setMediaOnDynamic({
              platform: typedPlatform,
              creativeMedias: appropriateImages!.slice(0, 3),
            })
          );
          break;
        case CreativeTypesEnum.CAROUSEL:
          const { caption, headline, description, link, propertyId  } = creativeFormsState[typedPlatform].carousel!;
          dispatch(
            setCreativeItemsOnCarousel({
              platform: typedPlatform,
              creativeItems: [0, 1, 2].map((i) => ({
                caption: caption?.slice(0, 34) || '',
                headline:  headline?.slice(0, 34) || '',
                description: description?.slice(0, 34) || '',
                link: link?.slice(0, 34) || '',
                propertyId: propertyId || '',
                creativeMedia: appropriateImages![i] || null,
              })),
            })
          );
          break;
      }
    }
  };

  return { dispatchMediaPerPlatform };
};

export default usePlatformMedia;

// // State
// - Active platforms tracking
// - Platform-specific media assignments
// - Creative type configurations

// // Methods
// - Assign media per platform/creative type
// - Handle platform-specific requirements
// - Manage carousel/dynamic/single variations

// return {
//   platformMediaMap,
//   assignMedia,
//   mediaConfigurations
// }
