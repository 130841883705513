import KeyboardDoubleArrowLeft from '@mui/icons-material/KeyboardDoubleArrowLeft';
import Restore from '@mui/icons-material/Restore';
import AddToPhotosTwoToneIcon from '@mui/icons-material/AddToPhotosTwoTone';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { SyntheticEvent, FC, type JSX, useState } from 'react';
import { Menu, MenuItem, Sidebar, sidebarClasses, useProSidebar } from 'react-pro-sidebar';
import { useNavigate } from 'react-router-dom';
import { deleteCredentials, setCredentials } from '@/auth/store/reducers/auth.slice';
import {
  cancelImpersonation,
  clearLS,
  getValueFromLs,
  impersonationBackToken,
  refreshLSAfterAdCreation,
  setKeyValuePairToLS,
} from '@/shared/services/localstorage';
import useCurrentPath from '../../store/hooks/location';
import { useAppDispatch } from '@/store/hooks/redux';
import { useGetMeQuery } from '../services/http/users.api';
import { EDITOR_LS_CONSTANTS } from '../shared/constants/editor-LS.constants';
import { setLeaveAdCreationSteps } from '../store/reducers/modals-drawers.slice';
import { setCarouselPropertyExtId, setPropertyExtId } from '../store/reducers/property-info.slice';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import { useTranslation } from 'react-i18next';
import VideoLibraryOutlinedIcon from '@mui/icons-material/VideoLibraryOutlined';
import { removeAllPlaces } from '../store/reducers/adSet-step.slice';
import { setInitialized } from '../store/reducers/creative-step.slice';

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  '.ps-menu-button:hover': {
    color: `${theme.palette.secondary.light} !important`,
    backgroundColor: 'transparent !important',
  },
  '.ps-menu-button': {
    color: `#fff !important`,
  },
}));

const SideBar: FC = (): JSX.Element => {
  const currPath = useCurrentPath();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isCollapsed, setCollapsed] = useState<boolean>(true);

  const activeStepFromLS = JSON.parse(getValueFromLs(EDITOR_LS_CONSTANTS.CREATE_ORDER_ACTIVE_STEP) as string);
  const { data: userData } = useGetMeQuery();
  const { t } = useTranslation();

  const handleSideBarMenuItemClick = (path: string) => {
    if (currPath.includes('/advertise/add')) {
      dispatch(setLeaveAdCreationSteps({ isOpened: true, pathNavigateTo: path }));
      return;
    }

    navigate('/editor');
  };

  const handleClickOnFinishOrder = () => {
    navigate('advertise/add');
  };

  const handleCancelImpersonation = () => {
    const adminCreds = cancelImpersonation();
    refreshLSAfterAdCreation();
    if (adminCreds) {
      dispatch(setCredentials(adminCreds));
      navigate('/dashboard/users');
    } else {
      navigate('/sign-in');
    }
  };

  const handleClickOnNewAd = () => {
    setKeyValuePairToLS(EDITOR_LS_CONSTANTS.CREATE_ORDER_ACTIVE_STEP, '0');

    refreshLSAfterAdCreation();

    dispatch(setPropertyExtId(''));
    dispatch(setCarouselPropertyExtId(''));
    dispatch(removeAllPlaces());
    dispatch(setInitialized(false));

    navigate('advertise/add');
  };

  return (
    <Sidebar
      collapsed={isCollapsed}
      rootStyles={{
        [`.${sidebarClasses.container}`]: {
          backgroundColor: '#000',
        },
      }}>
      <Menu>
        <StyledMenuItem
          icon={
            isCollapsed && (
              <IconButton onClick={() => setCollapsed(false)}>
                <MenuOutlinedIcon sx={{ color: '#fff' }} />
              </IconButton>
            )
          }
          style={{
            margin: '10px 0',
          }}>
          {!isCollapsed && (
            <Box component="div" display="flex" justifyContent="space-between" alignItems="center" ml="15px">
              <img style={{ width: '75%' }} src="/makeads_logo_white.png" alt="logo" />
              <IconButton sx={{ color: '#fff' }} onClick={() => setCollapsed(true)}>
                <KeyboardDoubleArrowLeft />
              </IconButton>
            </Box>
          )}
        </StyledMenuItem>
        {!isCollapsed && (
          <Box component="div" mb="25px">
            <Box component="div" display="flex" justifyContent="center" alignItems="center">
              <img
                className="avatar-image"
                alt="profile user"
                src={userData?.profile?.avatar || '/avatar.png'}
                onError={(e: SyntheticEvent<HTMLImageElement, Event>) => {
                  return (e.currentTarget.src = '/avatar.png');
                }}
                style={{
                  cursor: 'pointer',
                  borderRadius: '5px',
                  width: '200px',
                  height: '200px',
                  objectFit: 'cover',
                }}
              />
            </Box>
            <Box component="div" textAlign="center">
              <Typography variant="h4" sx={{ m: '10px 0 0 0', color: '#fff' }}>
                {userData?.profile?.firstName} {userData?.profile?.lastName}
              </Typography>
            </Box>
          </Box>
        )}
        <StyledMenuItem
          title="Main Page"
          icon={<HomeOutlinedIcon />}
          onClick={() => handleSideBarMenuItemClick('/editor')}>
          <Typography marginLeft={2.5} variant="h5">
            {t('mainPage')}
          </Typography>
        </StyledMenuItem>
        <StyledMenuItem title="New advertisement" icon={<AddToPhotosTwoToneIcon />} onClick={handleClickOnNewAd}>
          <Typography marginLeft={2.5} variant="h5">
            {t('newAdvertisement')}
          </Typography>
        </StyledMenuItem>
        {activeStepFromLS > 0 && (
          <StyledMenuItem title="Finish order" icon={<Restore />} onClick={handleClickOnFinishOrder}>
            <Typography marginLeft={2.5} variant="h5">
              {t('finishOrder')}
            </Typography>
          </StyledMenuItem>
        )}

        <StyledMenuItem
          title="Video Maker"
          icon={<VideoLibraryOutlinedIcon />}
          onClick={() => navigate('videoLibrary')}>
          <Typography marginLeft={2.5} variant="h5">
            {t('VideoMaker')}
          </Typography>
        </StyledMenuItem>
        <StyledMenuItem
          title="Profile"
          icon={<AccountCircleTwoToneIcon />}
          onClick={() => {
            navigate(`users/${userData?.id!}/user-profile`);
          }}>
          <Typography marginLeft={2.5} variant="h5">
            {t('profile')}
          </Typography>
        </StyledMenuItem>
        {getValueFromLs(impersonationBackToken) && (
          <StyledMenuItem title="Back to admin" icon={<AdminPanelSettingsIcon />} onClick={handleCancelImpersonation}>
            <Typography marginLeft={2.5} variant="h5">
              {t('backToAdmin')}
            </Typography>
          </StyledMenuItem>
        )}
        <StyledMenuItem
          title="Log out"
          icon={<LogoutTwoToneIcon />}
          onClick={() => {
            clearLS();
            dispatch(deleteCredentials());
            navigate('/sign-in');
          }}>
          <Typography marginLeft={2.5} variant="h5">
            {t('logOut')}
          </Typography>
        </StyledMenuItem>
      </Menu>
    </Sidebar>
  );
};

export default SideBar;
