import { useEffect, useState, useCallback, useRef } from 'react';
import { useFormikContext } from 'formik';
import { useParams } from 'react-router-dom';
import { useGetRetailPropertyDataQuery } from '@/editor/services/http/retail-data-provider.api';
import { useGetOrderWithDetailsByIdQuery } from '@/editor/services/http/orders.api';
import { useLazyGetMediaLibraryImagesQuery } from '@/editor/services/http/media-library.api';
import { getValueFromLs, setKeyValuePairToLS } from '@/shared/services/localstorage';
import { EDITOR_LS_CONSTANTS } from '@/editor/shared/constants/editor-LS.constants';
import toast from 'react-hot-toast';
import { handleError, syncDownloadedData, updateFormikValues } from '../CarouselCreativeItemFunctions';
import { ICarouselCreativeFormValuesModel } from '../models/carousel-creative-form-values';
import { PlatformType } from '@/editor/shared/constants/available-platforms';
import { useAppDispatch, useAppSelector } from '@/store/hooks/redux';
import { updateFieldOfCarouselItem } from '@/editor/store/reducers/creative-step.slice';
import useRegisterCarouselProperty, { useRegisterPropertyEffect } from './use-carousel-register-property-hook';
export const useCarouselCreativeItem = (
carouselItemIndex: number,
  platform: PlatformType
) => {
  const dispatch = useAppDispatch();
  const formikCtx = useFormikContext<ICarouselCreativeFormValuesModel>();
  const propertyCarouselExtId = useAppSelector((state) => state.propertyInfoSliceReducer.propertyCarouselExtId);
  const companyId = useAppSelector((state) => state.propertyInfoSliceReducer.companyId);
  const { id } = useParams<{ id: string }>();
  const { currentData, isError, isLoading, isFetching } = useGetRetailPropertyDataQuery(
    { propertyId: propertyCarouselExtId ?? '', companyId: companyId },
    { skip: !propertyCarouselExtId }
  );
  const propertyInternalId = getValueFromLs(EDITOR_LS_CONSTANTS.PROPERTY_INTERNAL_ID);
  const prevPropertyId = useRef<string | null>(propertyInternalId);

  const [ loadMediaLibraryData, { data: mediaLibraryImageData, isLoading: isLoadPropertyLoading },] = useLazyGetMediaLibraryImagesQuery();

  const { data: orderData } = useGetOrderWithDetailsByIdQuery({ id: id! }, { skip: !id });
  const downloadedCarouselIndex: string | null = getValueFromLs(EDITOR_LS_CONSTANTS.DOWNLOADED_CAROUSEL_INDEX);
  const existingDownloadedData = JSON.parse(getValueFromLs(EDITOR_LS_CONSTANTS.DOWNLOADED_CAROUSEL_DATA) || '[]');
  const [updatedIndex, setUpdatedIndex] = useState(0);

  const updateCarouselItemField = useCallback((platform: PlatformType, index: number, field: string, value: string) => {
    dispatch(updateFieldOfCarouselItem({ platform, index, field, value }));
  }, [dispatch]);

  const registerProperty = useRegisterCarouselProperty(currentData, downloadedCarouselIndex, carouselItemIndex);
  useRegisterPropertyEffect(registerProperty);

  useEffect(() => {
    handleError(isError, dispatch, toast);
    syncDownloadedData(downloadedCarouselIndex, currentData!, existingDownloadedData, setKeyValuePairToLS);
    updateFormikValues(
      formikCtx,
      existingDownloadedData,
      platform,
      updateCarouselItemField,
      mediaLibraryImageData!,
      downloadedCarouselIndex!
    );

    if (currentData && propertyInternalId && prevPropertyId.current !== propertyInternalId) {
      loadMediaLibraryData({ propertyId: propertyInternalId });
      prevPropertyId.current = propertyInternalId;
    }
  }, [currentData, isError, updatedIndex, downloadedCarouselIndex, mediaLibraryImageData, propertyInternalId]);

  return {
    isLoading,
    isFetching,
    orderData,
    updateCarouselItemField,
    downloadedCarouselIndex,
    setUpdatedIndex,
  };
};