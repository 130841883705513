import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { IMediaLibraryImageModel } from '../../../shared/models/media-library/media-library-image.model';
import { api, TypeIdsDefinitions } from '../../../shared/services/api/api';
import providesList from '../../../shared/services/helpers/cache.helper';
import { IMediaLibraryVideoModel } from './../../../shared/models/media-library/media-library-video.model';
import { IPropertyApiResponseModel } from '@/shared/models/property.api.response.model';
import { IPropertyModel } from '@/shared/models/property.model';
import { ILinkToUploadMedia } from '@/shared/models/media-library/link-to-upload-media.model';
import { IBasicCreativeFormValues } from '@/editor/shared/components/forms/creatives/models/basic-creative-form-values.model';

const baseUrl = 'property-files';

export interface IVideoFromPropertyId {
  videos: IMediaLibraryVideoModel[];
  registeredPropertyData: IPropertyModel;
}

export interface IOverlayResponse {
  imageUrl: string;
  data: Partial<IBasicCreativeFormValues>;
  propertyFileImageId: string;
}

export const mediaLibraryApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMediaLibraryImages: build.query<IMediaLibraryImageModel[], { propertyId: string }>({
      query: ({ propertyId }) => ({
        url: `${baseUrl}/properties/${propertyId}/images`,
      }),
      providesTags: (result) => providesList(result ?? [], 'MediaLibraryImages', TypeIdsDefinitions.listTypeId),
    }),
    getMediaLibraryVideos: build.query<IMediaLibraryVideoModel[], { propertyId: string }>({
      query: ({ propertyId }) => ({
        url: `${baseUrl}/properties/${propertyId}/videos`,
      }),
    }),
    getVideosFromPropertyId: build.query<IVideoFromPropertyId, { propertyId: string; companyId: string }>({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const propertyQuery = await fetchWithBQ(`retail-providers/data/${_arg.companyId}/${_arg.propertyId}`);

        if (propertyQuery.error) return { error: propertyQuery.error as FetchBaseQueryError };

        const propertyData = propertyQuery.data as IPropertyApiResponseModel;
        const registerPropertyRequest = await fetchWithBQ({
          url: `/properties`,
          method: 'POST',
          body: { propertyId: _arg.propertyId, address: propertyData.propertyAddress },
        });

        if (registerPropertyRequest.error) return { error: registerPropertyRequest.error as FetchBaseQueryError };
        const registeredPropertyData = registerPropertyRequest.data as IPropertyModel;

        const videosQuery = await fetchWithBQ(`${baseUrl}/properties/${registeredPropertyData.id}/videos`);

        return videosQuery.data
          ? {
              data: {
                videos: videosQuery.data as IMediaLibraryVideoModel[],
                registeredPropertyData: registeredPropertyData,
              },
            }
          : { error: videosQuery.error as FetchBaseQueryError };
      },
    }),
    uploadVideo: build.mutation<
      ILinkToUploadMedia,
      { extension: string; fileName: string; propertyId: string; file: File }
    >({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const uploadVideoRequest = await fetchWithBQ({
          url: 'medias/videos',
          method: 'POST',
          body: _arg,
        });

        const uploadVideoResponse = uploadVideoRequest.data as ILinkToUploadMedia;

        if (uploadVideoRequest.error) return { error: uploadVideoRequest.error as FetchBaseQueryError };

        const uploadFile = await fetchWithBQ({
          url: uploadVideoResponse.linkToUpload!,
          method: 'PUT',
          body: _arg.file,
        });

        return uploadVideoRequest.data
          ? { data: uploadVideoRequest.data as ILinkToUploadMedia }
          : { error: uploadVideoRequest as FetchBaseQueryError };
      },
    }),
    uploadImage: build.mutation<
      ILinkToUploadMedia,
      { propertyId: string; extension: string; width: number; height: number; file: File }
    >({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const uploadImageRequest = await fetchWithBQ({
          url: 'medias/images',
          method: 'POST',
          body: _arg,
        });

        const uploadImageResponse = uploadImageRequest.data as ILinkToUploadMedia;
        if (uploadImageRequest.error) return { error: uploadImageRequest.error as FetchBaseQueryError };

        const uploadFile = await fetchWithBQ({
          url: uploadImageResponse.linkToUpload!,
          method: 'PUT',
          body: _arg.file,
          headers: {
            Authorization: '',
          },
        });

        return uploadImageRequest.data
          ? { data: uploadImageRequest.data as ILinkToUploadMedia }
          : { error: uploadImageRequest as FetchBaseQueryError };
      },
    }),
    getUserVideos: build.query<IMediaLibraryVideoModel[], void>({
      query: () => ({
        url: `user-files/videos`,
      }),
    }),
    createImageOverlay: build.query<IOverlayResponse, { propertyFileId: string }>({
      query: ({ propertyFileId }) => ({
        url: `${baseUrl}/${propertyFileId}/after-sold`,
        method: 'GET',
      }),
    }),
    createAndUploadOverlayFromPropertyId: build.mutation<
      IOverlayResponse,
      { internalPropertyId: string; images: IMediaLibraryImageModel[] }
    >({
      async queryFn({ internalPropertyId, images }, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          const overlayResult = await fetchWithBQ({
            url: `${baseUrl}/${images[0].id}/after-sold?withSave=1`,
            method: 'GET',
          });
          if (overlayResult.error) {
            return { error: overlayResult.error as FetchBaseQueryError };
          }

          const overlayData = overlayResult.data as IOverlayResponse;

          return { data: overlayData };
        } catch (error) {
          return {
            error: {
              status: 500,
              data: error instanceof Error ? error.message : 'Unknown error occurred',
            } as FetchBaseQueryError,
          };
        }
      },
    }),
  }),
});

export const {
  useGetMediaLibraryImagesQuery,
  useLazyGetMediaLibraryImagesQuery,
  useGetMediaLibraryVideosQuery,
  useLazyGetMediaLibraryVideosQuery,
  useGetVideosFromPropertyIdQuery,
  useLazyGetVideosFromPropertyIdQuery,
  useUploadVideoMutation,
  useUploadImageMutation,
  useGetUserVideosQuery,
  useLazyGetUserVideosQuery,
  useCreateImageOverlayQuery,
  useLazyCreateImageOverlayQuery,
  useCreateAndUploadOverlayFromPropertyIdMutation,
} = mediaLibraryApi;
