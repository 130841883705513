import Box from '@mui/material/Box';
import { useAppSelector } from '@/store/hooks/redux';
import EmptyImageCard from '../EmptyImageCard';

import type { JSX } from 'react';

const FacebookSinglePreview = (): JSX.Element => {
  const media = useAppSelector((state) => state.creativeStepSliceReducer.creativeFormsState.meta.single.media);

  return (
    <Box component="div">
      {media?.preSignedUrl ? (
        <Box
          sx={{
            display: 'flex',
            maxWidth: '600px',
            maxHeight: '400px',
            justifyContent: 'center',
          }}>
          <img
            src={media?.preSignedUrl ?? ''}
            style={{
              objectFit: 'contain',
              overflow: 'hidden',
            }}
            alt="dynamic-image"></img>
        </Box>
      ) : (
        <EmptyImageCard></EmptyImageCard>
      )}
    </Box>
  );
};

export default FacebookSinglePreview;
