import { useEffect, useState } from 'react';
import { getValueFromLs } from '@/shared/services/localstorage';
import { useAppSelector } from '@/store/hooks/redux';
import { EDITOR_LS_CONSTANTS } from '@/editor/shared/constants/editor-LS.constants';
import { setInitialized, setMediaOnSingle } from '@/editor/store/reducers/creative-step.slice';
import { useAppDispatch } from '@/store/hooks/redux';
import usePropertyData from './usePropertyData';
import useOverlayManagement from './useOverlayManagement';
import useMediaLibrary from './useMediaLibrary';
import { IMediaLibraryImageModel } from '@/shared/models/media-library/media-library-image.model';

const usePrepareCreatives = () => {
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const isDefaultOvelray = useAppSelector((state) => state.creativeStepSliceReducer.isOverlayDefault);
  const propertyInternalId = getValueFromLs(EDITOR_LS_CONSTANTS.PROPERTY_INTERNAL_ID) as string;
  const isCreativeFormInitialized = useAppSelector((state) => state.creativeStepSliceReducer.isInitialized);

  const { preparePropertyData, formData, loadingState } = usePropertyData();
  const { getOverlayData, loadingOverlay } = useOverlayManagement();
  const { getMediaLibraryData, initializeMediaLibrary, imagesLibrary, mediaLibraryFetching } = useMediaLibrary();

  const initializeFormWithOverlay = async (images: IMediaLibraryImageModel[]) => {
    return await getOverlayData(propertyInternalId, images);
  };

  useEffect(() => {
    if (!firstRender || isCreativeFormInitialized) return;

    const initializeData = async () => {
      try {
        await preparePropertyData();
        await initializeMediaLibrary(propertyInternalId);
        dispatch(setInitialized(true));
        if (!isDefaultOvelray) setFirstRender(false);
      } catch (error) {
        console.error('Error during initialization:', error);
      }
    };

    initializeData();
  }, [imagesLibrary]);

  useEffect(() => {
    if (!formData || !imagesLibrary || !firstRender) return;

    const initializeOverlay = async () => {
      if (isDefaultOvelray) {
        const overlayResponse = await initializeFormWithOverlay(imagesLibrary);
        const res = await getMediaLibraryData(propertyInternalId);
        const imageObject = res?.find((image) => image.id === overlayResponse.data?.propertyFileImageId);
        dispatch(setMediaOnSingle({ media: imageObject!, platform: overlayResponse.activePlatforms[0] }));
        setFirstRender(false);
      }
    };

    initializeOverlay();
  }, [formData, imagesLibrary]);

  return { loading: loadingState.isFetching || mediaLibraryFetching || loadingOverlay };
};

export default usePrepareCreatives;
