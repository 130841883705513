import { IBasicCreativeFormValues } from '@/editor/shared/components/forms/creatives/models/basic-creative-form-values.model';
import { IPropertyApiResponseModel } from '@/shared/models/property.api.response.model';
import moment from 'moment';

export const propertyDataService = {
  transformToFormValues(apiResponse: IPropertyApiResponseModel): IBasicCreativeFormValues {
    const propertyViewDate = apiResponse?.propertyViewDate?.date
      ? moment(apiResponse?.propertyViewDate?.date).format('YYYY-MM-DD H')
      : '';

    return {
      headline: apiResponse?.propertyDescription,
      caption: apiResponse?.propertyAddress,
      description: apiResponse?.propertyHeading,
      link: apiResponse?.propertyLink,
      ownershipType: apiResponse?.buildingOwnershipType,
      areaInUsage: apiResponse?.propertySquareMeters,
      viewDate: propertyViewDate,
      stateDebt: apiResponse?.propertyDebt,
      priceQuote: apiResponse?.propertyPrice,
      propertyEstateType: apiResponse?.propertyEstateType,
      bidStateDebt: apiResponse?.bidPropertyDebt,
      bidPriceQuote: apiResponse?.bidPropertyPrice,
      propertyDescription2: apiResponse?.propertyDescription2,
      nativeAd: apiResponse?.nativeAd,
      employeeName: apiResponse?.employeeName,
      employeeEmail: apiResponse?.employeeEmail,
      employeePhone: apiResponse?.employeePhone,
      employeeAvatar: apiResponse?.employeeAvatar,
      doohBrokerText: apiResponse?.doohBrokerText,
      doohTemplateType: apiResponse?.doohTemplateType,
      propertyId: apiResponse?.propertyId,
    };
  },
};
