import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import { type JSX, FC } from 'react';
import CommonButton from '../../../../../shared/components/CommonButton';
import { PlatformType } from '../../../constants/available-platforms';
import { ICarouselCreativeFormValuesModel, ICarouselCreativeItemFormValuesModel } from './models/carousel-creative-form-values';
import useHandleCarouselDelete from './hooks/use-carousel-delete-hook';
import useHandleCarouselDownload from './hooks/use-carousel-download-hook';
import useHandleCarouselChange from './hooks/use-carousel-change-hook';
import useHandleCarouselMedia from './hooks/use-carousel-media-hook';
import CarouselCreativeItemDetails from './CarouselCreativeItemDetails';
import CarouselCreativeItemHeader from './CarouselCreativeItemHeader';
import { getValueFromLs, setKeyValuePairToLS } from '@/shared/services/localstorage';
import { useFormikContext } from 'formik';
import { useCarouselCreativeItem } from './hooks/use-creative-item';
export interface ICarouselCreativeItemProps {
  carouselItem: ICarouselCreativeItemFormValuesModel;
  carouselItemIndex: number;
  platform: PlatformType;
  openMediaLibrary: (index: number) => void;
}
const CarouselCreativeItem: FC<ICarouselCreativeItemProps> = ({
  platform,
  carouselItem,
  carouselItemIndex,
  openMediaLibrary,
}): JSX.Element => {
  const {
    isLoading,
    isFetching,
    orderData,
    updateCarouselItemField,
    downloadedCarouselIndex,
    setUpdatedIndex,
  } = useCarouselCreativeItem(carouselItemIndex ,platform);
  const formikCtx = useFormikContext<ICarouselCreativeFormValuesModel>();
  const creative = orderData?.advertisements?.find((ad) => ad.platform === 'meta')?.creative;
  const handleMediaLibraryClick = useHandleCarouselMedia(
    creative,
    setKeyValuePairToLS,
    getValueFromLs,
    openMediaLibrary
  );
  const onDeleteItemClick = useHandleCarouselDelete(formikCtx, platform, setUpdatedIndex);
  const handleClickOnDownloadIconCarousel = useHandleCarouselDownload(
    formikCtx,
    platform,
    updateCarouselItemField,
    setUpdatedIndex
  );
  const handleChange = useHandleCarouselChange(setKeyValuePairToLS, updateCarouselItemField);
  return (
    <Grid item xs={12} key={carouselItemIndex} sx={{ mt: carouselItemIndex === 0 ? 3 : 0 }}>
      <Accordion TransitionProps={{ unmountOnExit: false }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <CarouselCreativeItemHeader
            carouselItemIndex={carouselItemIndex}
            handleMediaLibraryClick={handleMediaLibraryClick}
            onDeleteItemClick={onDeleteItemClick}
          />
        </AccordionSummary>
        <AccordionDetails>
          <CarouselCreativeItemDetails
            carouselItem={carouselItem}
            carouselItemIndex={carouselItemIndex}
            platform={platform}
            downloadedCarouselIndex={downloadedCarouselIndex}
            isLoading={isLoading}
            isFetching={isFetching}
            handleMediaLibraryClick={handleMediaLibraryClick}
            handleChange={handleChange}
            handleClickOnDownloadIconCarousel={handleClickOnDownloadIconCarousel}
            formikCtx={formikCtx}
          />
        </AccordionDetails>
        <AccordionActions>
          <CommonButton size="small" color="error" onClick={() => onDeleteItemClick(carouselItemIndex)}>
            Delete
          </CommonButton>
        </AccordionActions>
      </Accordion>
    </Grid>
  );
};

export default CarouselCreativeItem;
