import { useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useAppDispatch, useAppSelector } from '@/store/hooks/redux';
import { setPropertyExtId } from '@/editor/store/reducers/property-info.slice';
import { useRegisterPropertyMutation } from '@/editor/services/http/properties.api';
import { generateId } from '@/shared/services/helpers/random-string.helper';
import { setKeyValuePairToLS, deleteKeyValuePairFromLS } from '@/shared/services/localstorage';
import { EDITOR_LS_CONSTANTS } from '@/editor/shared/constants/editor-LS.constants';
import { toast } from 'react-hot-toast';
import { useLazyGetRetailPropertyDataQuery } from '@/editor/services/http/retail-data-provider.api';

const validationSchema = yup.object().shape({
  address: yup.string().required('Address is required'),
  propertyId: yup.string().required('Property id is required'),
});

export const usePropertyRegistration = (onSuccess: (step: number) => void) => {
  const dispatch = useAppDispatch();
  const propertyExtId = useAppSelector((state) => state.propertyInfoSliceReducer.propertyExtId);
  const companyId = useAppSelector((state) => state.propertyInfoSliceReducer.companyId);

  const [registerProperty, { isLoading: isRegistering, isError: registrationError }] = useRegisterPropertyMutation();
  const [getRetailData, { isFetching: isLoadingRetailData, isError: retailDataError }] =
    useLazyGetRetailPropertyDataQuery();

  const formik = useFormik({
    initialValues: {
      address: '',
      propertyId: propertyExtId || generateId(20),
    },
    validationSchema,
    onSubmit: async (values) => {
      const response = await registerProperty(values);
      if ('data' in response) {
        const { id, address } = response.data!;
        setKeyValuePairToLS(EDITOR_LS_CONSTANTS.PROPERTY_INTERNAL_ID, id);
        setKeyValuePairToLS(EDITOR_LS_CONSTANTS.ORDER_TITLE, address);
        setKeyValuePairToLS(EDITOR_LS_CONSTANTS.PROPERTY_CAROUSEL_INTERNAL_ID, id);
        onSuccess(1);
      }
    },
  });

  const fetchRetailData = async (id: string) => {
    const response = await getRetailData({ propertyId: id, companyId }, true);
    if ('data' in response) {
      formik.setFieldValue('propertyId', response.data?.propertyId);
      formik.setFieldValue('address', response.data?.propertyAddress);
      dispatch(setPropertyExtId(id));
      setKeyValuePairToLS(EDITOR_LS_CONSTANTS.PROPERTY_EXTERNAL_ID, id);
    }
  };

  useEffect(() => {
    if (registrationError) {
      toast.error('Unknown property id');
      deleteKeyValuePairFromLS(EDITOR_LS_CONSTANTS.PROPERTY_EXTERNAL_ID);
      dispatch(setPropertyExtId(''));
    }
  }, [registrationError, dispatch]);

  useEffect(() => {
    if (propertyExtId) {
      fetchRetailData(propertyExtId);
    }
  }, []);

  return {
    formik,
    isRegistering,
    isLoadingRetailData,
    fetchRetailData,
  };
};
